import { ReportHeader } from './ReportHeader.model';

export class ReportHeaderSummary {
  new: ReportHeader[] = [];
  recent: ReportHeader[] = [];
  upcoming: ReportHeader[] = [];
  updated: ReportHeader[] = [];
  recently_viewed: ReportHeader[] = [];
  totalNew!: number;
  totalUpcoming!: number;
  totalUpdated!: number;
  totalRecentlyViewed!: number;
  totalAll!: number;
  hasResult!: boolean;
  dashboardResults: ReportHeader[] = [];
  constructor(reportHeaderSummary: ReportHeaderSummary) {
    if (reportHeaderSummary) {
      this.new = reportHeaderSummary.new;
      this.recent = reportHeaderSummary.recent;
      this.upcoming = reportHeaderSummary.upcoming;
      this.updated = reportHeaderSummary.updated;
      this.recently_viewed = reportHeaderSummary.recently_viewed;
      this.totalNew = reportHeaderSummary.totalNew;
      this.totalUpcoming = reportHeaderSummary.totalUpcoming;
      this.totalUpdated = reportHeaderSummary.totalUpdated;
      this.totalRecentlyViewed = reportHeaderSummary.totalRecentlyViewed;
      this.totalAll = reportHeaderSummary.totalAll;
      this.dashboardResults = this.dashboardResultsSummary([
        ...reportHeaderSummary.recent,
        ...reportHeaderSummary.new,
        ...reportHeaderSummary.updated,
        ...reportHeaderSummary.upcoming,
      ]);
    }
  }

  dashboardResultsSummary(results: ReportHeader[]) {
    const len: number = results.length;

    for (let i = 0; i < len - 1; i++) {
      let maxIndex = i;

      for (let j = i + 1; j < len; j++) {
        const dateWithI = this.isOnDemand(results[maxIndex].accountName)
          ? new Date(results[maxIndex].orderedDate)
          : new Date(results[maxIndex].dateOfService);
        const dateWithJ = this.isOnDemand(results[j].accountName)
          ? new Date(results[j].orderedDate)
          : new Date(results[j].dateOfService);

        if (dateWithJ > dateWithI) {
          maxIndex = j;
        }
      }

      if (maxIndex !== i) {
        const temp = results[i];
        results[i] = results[maxIndex];
        results[maxIndex] = temp;
      }
    }

    return results.slice(0, 6);
  }

  isOnDemand(accountName: string): boolean {
    return accountName
      ? accountName.toLowerCase().includes('labcorp ondemand')
      : false;
  }
}

import { createAction, props } from '@ngrx/store';

import {
  ActivateFactorResponse,
  MFACatalogItem,
  OktaEnrolledFactor,
  OktaFactorEnrollResponse,
} from '@patient-ui/shared/models';

export const loadEnrolledFactors = createAction('[MFA] Load Enrolled Factors');

export const loadEnrolledFactorsSuccess = createAction(
  '[MFA] Load Enrolled Factors Success',
  props<{ factors: OktaEnrolledFactor[] }>()
);

export const loadEnrolledFactorsFailure = createAction(
  '[MFA] Load Enrolled Factors Failure',
  props<{ error: Error }>()
);

export const loadMFACatalog = createAction('[MFA] Load Catalog');

export const loadMFACatalogSuccess = createAction(
  '[MFA] Load Catalog Success',
  props<{ factorCatalog: MFACatalogItem[] }>()
);

export const loadMFACatalogFailure = createAction(
  '[MFA] Load Catalog Failure',
  props<{ error: Error }>()
);

export const enrollFactor = createAction(
  '[Factors] Enroll Factor',
  props<{ factorType: string; phoneNumber?: string }>()
);

export const enrollFactorSuccess = createAction(
  '[Factors] Enroll Factor Success',
  props<{
    enrollFactorResponse: OktaFactorEnrollResponse;
  }>()
);

export const enrollFactorFailure = createAction(
  '[Factors] Enroll Factor Failure',
  props<{ error: Error }>()
);

export const activateFactor = createAction(
  '[Factors] Activate Factor',
  props<{ factorId: string; passCode: string }>()
);

export const activateFactorSuccess = createAction(
  '[Factors] Activate Factor Success',
  props<{ factor: ActivateFactorResponse }>()
);

export const activateFactorFailure = createAction(
  '[Factors] Activate Factor Failure',
  props<{ error: Error }>()
);

export const deleteFactor = createAction(
  '[Factors] Delete Factor',
  props<{ factorId: string; enrolledDataLength: number }>()
);

export const deleteFactorSuccess = createAction(
  '[Factors] Delete Factor Success'
);

export const deleteFactorFailure = createAction(
  '[Factors] Delete Factor Failure',
  props<{ error: Error }>()
);

export const retriveMFAStatus = createAction('[Factors] Retrive MFA Status');
export const retriveMFAStatusSuccess = createAction(
  '[Factors] Retrive MFA Status Success',
  props<{ mfaSelectionStatus: boolean | null | undefined }>()
);
export const retriveMFAStatusFailure = createAction(
  '[Factors] Retrive MFA Status Failure',
  props<{ error: Error }>()
);

export const setMFAPreference = createAction(
  '[Factors] Set MFA Preference',
  props<{ preference: boolean }>()
);
export const setMFAPreferenceSuccess = createAction(
  '[Factors] Set MFA Preference Success'
);
export const setMFAPreferenceFailure = createAction(
  '[Factors] Set MFA Preference Failure',
  props<{ error: Error }>()
);
export const clearEnrollFactorResponse = createAction(
  '[Factors] Clear Enroll Factor Response'
);
export const clearEnrolledFactors = createAction(
  '[Factors] Clear Enrolled Factors'
);

//For Guest Enrollment Verification
export const loadGuestEnrolledFactors = createAction(
  '[MFA] Load Guest Enrolled Factors',
  props<{ oktaID: string }>()
);

export const loadGuestEnrolledFactorsSuccess = createAction(
  '[MFA] Load Guest Enrolled Factors Success',
  props<{ factors: OktaEnrolledFactor[] }>()
);

export const loadGuestEnrolledFactorsFailure = createAction(
  '[MFA] Load Guest Enrolled Factors Failure',
  props<{ error: Error }>()
);

export const verifyFactor = createAction(
  '[Factors] Verify Factor',
  props<{ factorId: string; oktaID: string; passCode: string }>()
);

export const verifyFactorSuccess = createAction(
  '[Factors] Verify Factor Success',
  props<{ factor: ActivateFactorResponse }>()
);

export const verifyFactorSuccessWithoutPasscode = createAction(
  '[Factors] Verify Factor Success Without Passcode',
  props<{ factor: ActivateFactorResponse }>()
);

export const verifyFactorSuccessForOktaPush = createAction(
  '[Factors] Verify Factor Success For Okta Push',
  props<{ factor: ActivateFactorResponse }>()
);

export const verifyFactorFailure = createAction(
  '[Factors] Verify Factor Failure',
  props<{ error: Error }>()
);

export const getPasscodeFailure = createAction(
  '[Factors] Get Passcode Failure',
  props<{ error: Error }>()
);

export const resetVerifiedErrorCount = createAction(
  '[Factors] Reset Verified Error Count'
);

export const resetVerificationStatus = createAction(
  '[Factors] Reset Verification Status'
);

export const resetEnrolledFactor = createAction(
  '[Factors] Reset Enrolled Factor'
);

export const resetPhoneCodetatus = createAction(
  '[Factors] Reset Phone Code Status'
);

export const backToSendCodeScreen = createAction(
  '[Factors] Back To Send Code Status'
);

export const oktaPushVerified = createAction('[Factors] Okta Push Verified');

export const oktaPushNotVerified = createAction(
  '[Factors] Okta Push Not Verified'
);

export const MFAActions = {
  loadMFACatalog,
  loadMFACatalogSuccess,
  loadMFACatalogFailure,
  loadEnrolledFactors,
  loadEnrolledFactorsSuccess,
  loadEnrolledFactorsFailure,
  enrollFactor,
  enrollFactorSuccess,
  enrollFactorFailure,
  activateFactor,
  activateFactorSuccess,
  activateFactorFailure,
  deleteFactor,
  deleteFactorSuccess,
  deleteFactorFailure,
  retriveMFAStatus,
  retriveMFAStatusSuccess,
  retriveMFAStatusFailure,
  setMFAPreference,
  setMFAPreferenceSuccess,
  setMFAPreferenceFailure,
  clearEnrollFactorResponse,
  clearEnrolledFactors,
  loadGuestEnrolledFactors,
  loadGuestEnrolledFactorsSuccess,
  loadGuestEnrolledFactorsFailure,
  verifyFactor,
  verifyFactorSuccess,
  verifyFactorSuccessForOktaPush,
  verifyFactorSuccessWithoutPasscode,
  verifyFactorFailure,
  getPasscodeFailure,
  resetVerifiedErrorCount,
  resetEnrolledFactor,
  resetVerificationStatus,
  resetPhoneCodetatus,
  backToSendCodeScreen,
  oktaPushVerified,
  oktaPushNotVerified,
};

<div class="modal-body">
  <p class="success-message">
    <span class="material-icons icon-lg text-success pr-2">check_circle_outline</span>
    <span>Your identity was verified. You can continue creating your new account.</span>
  </p>
</div>
<div class="modal-footer justify-content-right">
  <button id="btnCancel" type="button" class="btn btn-secondary" (click)="cancel.emit()">Cancel</button>
  <button id="btnContinue" type="button" class="btn btn-primary" (click)="submitForm.emit()">Continue</button>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { cleanExactSixDigits } from '@patient-ui/shared/utils';

@Component({
  selector: 'patient-ui-okta-code-verification',
  templateUrl: './okta-code-verification.component.html',
  styleUrls: ['./okta-code-verification.component.scss'],
})
export class OktaCodeVerificationComponent {
  @Output() submitCode = new EventEmitter<string>();
  @Output() showFactorList = new EventEmitter<void>();
  @Input() verifiedStatus = false;
  @Input() verifiedError = false;
  @Input() verifyErrorCount = 0;
  @Input() isLoading = false;
  inputValue = '';
  constructor() {}

  /**
   * Handles the submit action for the input.
   */
  onSubmit() {
    this.submitCode.emit(this.inputValue);
  }

  /**
   * Handles the input event from the input field.
   * Sanitizes the value and updates the inputValue property.
   *
   * @param value - The current value of the input field.
   */
  onInput(event: FocusEvent | KeyboardEvent) {
    const val = (event.target as HTMLInputElement).value;
    this.inputValue = cleanExactSixDigits(val);
  }
}

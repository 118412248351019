export class ResultCodes {
  resultCode!: string;
  resultName!: string;
}

export class TestCodes {
  resultCodes!: ResultCodes[];
  testName!: string;
  testcode!: string;
}

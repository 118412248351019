import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'patient-ui-exit-confirmation-modal',
  templateUrl: './exit-confirmation-modal.component.html',
  styleUrls: ['./exit-confirmation-modal.component.scss'],
})
export class ExitConfirmationModalComponent {
  @Output() cancel = new EventEmitter<void>();
  @Output() back = new EventEmitter<void>();
}

import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import OktaAuth, {
  // AccessToken,
  AuthnTransaction,
  SessionObject,
  // Token,
  UserClaims,
} from '@okta/okta-auth-js';
import { BroadcastChannel } from 'broadcast-channel';
import { BehaviorSubject, Observable, from, of, throwError, timer } from 'rxjs';
import { filter, mergeMap, retryWhen, tap } from 'rxjs/operators';

import { MetricActions, MetricState } from '@patient-ui/patient-web/store';
import { RequestStatus } from '@patient-ui/shared/enums';
import { EventCode, IBadDebtMetric, Patient } from '@patient-ui/shared/models';
import {
  AuthConfig,
  AuthConfigService,
  EnvironmentService,
} from '@patient-ui/shared-ui/utils';

import * as AuthActions from './auth.actions';
import { authenticateFailure, updateLoginInfoSuccess } from './auth.actions';
import { AuthState } from './auth.reducer';
import { selectAuthenticationStatus } from './auth.selectors';
import { loadLoggedInPatient } from '../patient/patient.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  oktaAuth!: OktaAuth;
  broadcastChannel = new BroadcastChannel('auth-channel');
  isLoggedInOkta = false;
  scopes = ['openid', 'profile', 'email'];
  sessionID =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);
  isStarted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  tokenIsExpired = false;
  private oktaAuthSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  browserInfo = `Browser: ${navigator.userAgent}`;

  public get isAuthenticated$(): Observable<boolean> {
    return this.oktaAuthSub.asObservable();
  }

  constructor(
    private readonly http: HttpClient,
    private environmentService: EnvironmentService,
    private authConfigService: AuthConfigService,
    private authStore: Store<AuthState>,
    private metricStore: Store<MetricState>,
    private router: Router
  ) {
    this.logLoginMetric('Inside The Auth Service Constructor');
    this.authStore.select(selectAuthenticationStatus).subscribe((status) => {
      this.isLoggedInOkta = status === RequestStatus.Success;
    });

    // Listen to logout broadcast to keep tabs in sync
    this.broadcastChannel.onmessage = (message) => {
      if (message === 'logout') {
        window.location.reload();
      }
    };

    // Setup okta auth service
    this.authConfigService.authConfig$
      .pipe(filter((config: AuthConfig) => !!Object.keys(config).length))
      .subscribe(async (config: AuthConfig) => {
        this.logLoginMetric('Okta Service Setup');
        this.oktaAuth = new OktaAuth({
          clientId: config.clientId,
          issuer: config.issuer,
          scopes: this.scopes,
          pkce: true,
          tokenManager: {
            autoRenew: false,
            autoRemove: false,
            syncStorage: false,
          },
          ignoreLifetime: true,
        });

        // Login with new auth token
        // this.oktaAuth.tokenManager.on('added', (key, _newToken) => {
        // const originalUri = this.oktaAuth.getOriginalUri();
        // if (key === 'accessToken' && _newToken) {
        //   this.loginPatient(
        //     (_newToken as AccessToken).accessToken,
        //     originalUri
        //   );
        // }
        // });

        this.isStarted.next(true);
      });
  }

  login(
    username: string,
    password: string,
    redirect: string,
    context?: string
  ): Observable<void> {
    this.logLoginMetric('Initial Login Method Call' + username);
    return from(
      this.oktaAuth
        .signInWithCredentials({ username, password })
        .then((t: AuthnTransaction) => {
          this.logLoginMetric('signInWithCredentials success' + username);
          this.handleSignInResponse(t, redirect, context);
        })
        .catch((error) => {
          this.logLoginMetric(
            'signInWithCredentials error' + error + ' User: ' + username
          );
          this.authStore.dispatch(authenticateFailure());
        })
    );
  }

  private async handleSignInResponse(
    transaction: AuthnTransaction,
    redirect: string,
    context?: string
  ): Promise<void> {
    this.logLoginMetric('Handle Signin Response');
    if (transaction.status !== 'SUCCESS') {
      this.authStore.dispatch(authenticateFailure());
      return;
    }
    this.oktaAuthSub.next(true);
    this.oktaAuth.setOriginalUri(redirect);
    if (context) {
      //For ondemand this will be called
      this.logLoginMetric('Has context for handle sign in response');
      this.oktaAuth.session.setCookieAndRedirect(
        transaction.sessionToken,
        `${window.location.origin}/callback`
      );
    } else {
      this.logLoginMetric('Context else for handle sign in response');
      await this.oktaAuth.token.getWithRedirect({
        pkce: true,
        sessionToken: transaction.sessionToken,
        responseType: 'code',
        redirectUri: `${window.location.origin}/callback`,
      });
    }
  }

  async handleExistingSession(session: SessionObject, redirect?: string) {
    this.logLoginMetric('Handle Existing Session');
    if (session) {
      if (!sessionStorage.getItem('metricEmail')) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sessionStorage.setItem('metricEmail', (session as any).login);
      }
      this.logLoginMetric('Inside session condition ');
      // any type needed here as inferred session object actually has id
      // prop, but okta auth lib does not have it type yet
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sessionId = (session as any).id as string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const userId = (session as any).userId as string;
      const params = new URL(window.location.href).searchParams;
      this.authStore.dispatch(
        AuthActions.saveSession({ token: sessionId, userId: userId })
      );
      if (params?.get('context') && params?.get('redirecturl')) {
        window.location.href = params.get('redirecturl') as string;
        // Stop code execution to prevent accessing anon pages
        await new Promise((resolve) => setTimeout(resolve, 10000));
      } else if (!this.isLoggedInOkta) {
        if (this.haveClearedToken) {
          this.logLoginMetric('Try login with cookie ');
          // If session and no token, try logging in with cookie
          await this.loginPatient(undefined, redirect);
          return;
        }
        if (this.isTokenNeeded) {
          this.logLoginMetric(
            'Make Login Call get token without requiring credentials '
          );
          // Otherwise, get token without requiring credentials
          try {
            const resp = await this.oktaAuth.token.getWithoutPrompt({
              responseType: ['token', 'id_token'],
              scopes: this.scopes,
              redirectUri: `${window.location.origin}/callback`,
              prompt: 'none',
            });
            this.oktaAuth.tokenManager.setTokens(resp.tokens);
            await this.loginPatient(
              resp.tokens?.accessToken?.accessToken,
              redirect
            );
          } catch {
            this.logLoginMetric('Catch block at istoken needed ');
            this.authStore.dispatch(authenticateFailure());
          }
        }
      }
    } else {
      this.oktaAuthSub.next(false);
    }
  }

  getUserInfo(): Observable<UserClaims> {
    return from(this.oktaAuth.getUser());
  }

  logoutAPI() {
    this.oktaAuth.session.close();
    localStorage.removeItem('selectOpt');
    return this.http.delete(
      `${this.environmentService.baseUrl}/protected/patients/current/logout`
    );
  }

  logoutAllSessions(): Observable<boolean> {
    this.broadcastChannel.postMessage('logout');
    this.oktaAuthSub.next(false);
    this.oktaAuth.tokenManager.clear();
    return of(true);
  }

  redirect(redirect: string): Observable<unknown> {
    return from(this.router.navigate([redirect]));
  }

  async loginPatient(accessToken?: string, redirect?: string): Promise<void> {
    const token = accessToken?.trim() || this.accessToken;
    this.logLoginMetric('Inside Login Patient ' + token);
    try {
      const response = await this.http
        .post(
          `${this.environmentService.baseUrl}/protected/patients/current/login`,
          undefined,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: token ? 'Bearer ' + accessToken : '',
            },
            withCredentials: true,
          }
        )
        .pipe(
          retryWhen((errors) =>
            errors.pipe(
              tap(console.error),
              mergeMap((err, i) => (i > 3 ? throwError(err) : timer(1500)))
            )
          )
        )
        .toPromise();
      const payload = <Patient>response;
      sessionStorage.setItem(
        'metricEmail',
        payload.loginEmail ? payload.loginEmail : ''
      );
      this.oktaAuthSub.next(true);
      this.authStore.dispatch(updateLoginInfoSuccess({ payload }));
      this.authStore.dispatch(loadLoggedInPatient({ payload }));
      if (redirect) {
        this.router.navigate([redirect]);
      } else {
        this.router.navigate(['portal/dashboard']);
      }
    } catch (error) {
      this.logLoginMetric('Login Error --> ' + error + ' <--');
      // if (
      //   this.oktaAuth.tokenManager.getTokensSync().accessToken?.accessToken
      // ) {
      //   this.oktaAuth.tokenManager.clear();
      //   this.oktaAuth.session.close();
      // }
      this.oktaAuthSub.next(false);
      this.authStore.dispatch(AuthActions.authenticateFailure());
      sessionStorage.setItem(`originalPath`, this.router.url);
      this.router.navigate(['/landing']);
    }
  }

  logLoginMetric(alertText: string) {
    const badDebtMetric: IBadDebtMetric = {
      dashboardId: 'Login',
      eventCode: EventCode.loginLanded,
      alertMsg:
        alertText +
        ' USER EMAIL ==> ' +
        sessionStorage.getItem('metricEmail') +
        ' <--BROWSER DETAILS--> ' +
        this.browserInfo,
      templateId: 'LoginTemplateID',
      userType: 'Login',
      sessionID: this.sessionID,
    };
    this.metricStore.dispatch(
      MetricActions.logBadDebtMetric({ metrics: badDebtMetric })
    );
  }

  get accessToken() {
    return this.oktaAuth.tokenManager
      .getTokensSync()
      .accessToken?.accessToken.trim();
  }

  get haveClearedToken() {
    return (
      new Date().getTime() / 1000 <
      (this.oktaAuth.tokenManager.getTokensSync().accessToken?.expiresAt ?? 0)
    );
  }

  get isTokenNeeded() {
    return (
      this.oktaAuth.tokenManager.getTokensSync().accessToken?.accessToken ===
      undefined
    );
  }

  refreshSession() {
    return from(this.oktaAuth.session.refresh());
  }

  ngOnDestroy(): void {
    this.oktaAuth.stop();
  }
}

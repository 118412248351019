import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  FactorsState,
  MFAActions,
  getVerifiedFactorError,
  getVerifiedFactorStatus,
  getVerifyFactorErrorCount,
  mfaTechDifficultiesStatus,
  resetVerifiedErrorCount,
  selectGuestEnrolledFactors,
  selectMFALoading,
} from '@patient-ui/patient-web/store';
import { guestEnrollmentFactor } from '@patient-ui/shared/constants';

@Component({
  selector: 'patient-ui-duplicate-registration-verify-identity',
  templateUrl: './duplicate-registration-verify-identity.component.html',
  styleUrls: ['./duplicate-registration-verify-identity.component.scss'],
})
export class DuplicateRegistrationVerifyIdentityComponent implements OnInit {
  @Input() oktaID = '';
  @Output() routeToSignInEvent = new EventEmitter<void>();
  @Output() submitRegistrationFormEvent = new EventEmitter<void>();
  isLoading = false;
  mfaLoading$ = this.mfaStore.select(selectMFALoading);
  enrolledData$ = this.mfaStore.select(selectGuestEnrolledFactors);
  verifiedFactorStatus$ = this.mfaStore.select(getVerifiedFactorStatus);
  verifiedStatus = false;
  verifiedFactorError$ = this.mfaStore.select(getVerifiedFactorError);
  verificationError = false;
  verifyFactorErrorCount$ = this.mfaStore.select(getVerifyFactorErrorCount);
  mfaTechDifficulties$: Observable<boolean> = this.mfaStore.select(
    mfaTechDifficultiesStatus
  );
  mfaTechDifficultiesStatus = false;
  maskedNumber = '';
  errorCount = 0;
  showEnrolledFactor = false;
  showExitConfirmationModal = false;
  selectedFactor = '';
  selectedFactorID = '';
  factorList: guestEnrollmentFactor[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private mfaStore: Store<FactorsState>
  ) {}

  ngOnInit(): void {
    combineLatest([this.mfaLoading$])
      .pipe(
        tap(([response]) => {
          this.isLoading = response;
        })
      )
      .subscribe();
    combineLatest([this.verifyFactorErrorCount$])
      .pipe(
        tap(([response]) => {
          this.errorCount = response;
        })
      )
      .subscribe();
    combineLatest([this.verifiedFactorStatus$])
      .pipe(
        tap(([response]) => {
          this.verifiedStatus = response;
        })
      )
      .subscribe();
    combineLatest([this.verifiedFactorError$])
      .pipe(
        tap(([response]) => {
          this.verificationError = response;
        })
      )
      .subscribe();
    this.mfaTechDifficulties$.subscribe(
      (value) => (this.mfaTechDifficultiesStatus = value)
    );
    this.enrolledData$.subscribe((data) => this.generateFactorList(data));
  }

  /**
   * This function format the data and create the proper array to display in the modal.
   */
  generateFactorList(data: guestEnrollmentFactor[]) {
    this.factorList = [];
    data.map((item: guestEnrollmentFactor) => {
      if (item.factorType === 'sms' && item.provider === 'OKTA') {
        this.maskedNumber = this.formatPhoneNumber(
          item.profile?.phoneNumber || ''
        );
        this.factorList.push({
          factorType: 'phone',
          text: 'Phone',
          id: item.id,
          value: this.formatPhoneNumber(item.profile?.phoneNumber || ''),
          icon: 'assets/images/phone-icon.svg',
        });
      } else if (
        item.factorType === 'token:software:totp' &&
        item.provider === 'OKTA'
      ) {
        this.factorList.push({
          factorType: 'oktaCode',
          text: 'Okta Verify',
          id: item.id,
          value: 'Enter a code',
          icon: 'assets/images/okta-icon.svg',
        });
      } else if (item.factorType === 'push' && item.provider === 'OKTA') {
        this.factorList.push({
          factorType: 'oktaPush',
          text: 'Okta Verify',
          id: item.id,
          value: 'Push notification',
          icon: 'assets/images/okta-icon.svg',
        });
      } else if (
        item.factorType === 'token:software:totp' &&
        item.provider === 'GOOGLE'
      ) {
        this.factorList.push({
          factorType: 'googleAuth',
          text: 'Google Authenticator',
          id: item.id,
          icon: 'assets/images/google-auth-icon.svg',
        });
      }
    });
    if (data.length) {
      this.showEnrolledFactor = true;
    }
  }

  handleSelectFactor(factor: guestEnrollmentFactor) {
    this.selectedFactor = factor?.factorType || '';
    this.selectedFactorID = factor?.id || '';
  }

  /**
   * Determines the appropriate header for the modal based on the current state of the component.
   * @returns The header text for the Modal.
   */
  getModalHeader() {
    if (this.showExitConfirmationModal) {
      return 'Exit Account Creation';
    } else if (this.selectedFactor && this.verifiedStatus === true) {
      return 'Verified';
    } else if (this.selectedFactor && this.errorCount >= 3) {
      return 'Not Verified';
    } else if (this.selectedFactor) {
      switch (this.selectedFactor) {
        case 'phone':
          return 'Phone';
        case 'oktaCode':
          return 'Okta Verify';
        case 'oktaPush':
          return 'Okta Verify';
        case 'googleAuth':
          return 'Google Authenticator';
        default:
          return 'Verify Your Identity';
      }
    }
    return 'Verify Your Identity';
  }

  /**
   * Retrives the factor list by dispatching a load action to the store.
   *
   * This function triggers the loading of data through the store's action dispatcher.
   */
  async getEnrolledFactorList() {
    await this.mfaStore.dispatch(
      MFAActions.loadGuestEnrolledFactors({ oktaID: this.oktaID })
    );
  }

  verifyFactor(inputVal?: string) {
    this.mfaStore.dispatch(
      MFAActions.verifyFactor({
        factorId: this.selectedFactorID,
        oktaID: this.oktaID,
        passCode: inputVal ? inputVal : '',
      })
    );
  }

  /**
   * Gets the sorted list of data in alphabetical order.
   *
   * This getter sorts the `factorList` array
   *
   * @returns {Array} The sorted array of factorList
   */
  get sortedList() {
    return this.factorList.sort((a, b) => {
      const textA = a.text || '';
      const textB = b.text || '';
      return textA.localeCompare(textB);
    });
  }

  /**
   * Format a masked phone number to a specific display format.
   *
   * @param phoneNumber - The masked phone number (e.g., ********1234)
   * @returns (string) The formatted phone number in the format +1 XXX-XXX-XXXX.
   */
  formatPhoneNumber(phoneNumber: string) {
    const lastFourDigits = phoneNumber.slice(-4);
    return `XXX-XXX-${lastFourDigits}`;
  }

  /**
   * Redirects the user to the landing page
   *
   * This function is used across registration component to handle user redirection
   * after a specific action or process, such as on click on cancel button and close
   * the modal.
   */
  routeToSignIn() {
    this.activeModal.dismiss('cancel');
    this.routeToSignInEvent.emit();
  }

  /**
   * This function is used to submit the registration form data.
   */
  submitRegistrationForm() {
    this.activeModal.dismiss('cancel');
    this.submitRegistrationFormEvent.emit();
  }

  /**
   * Handles the action when the user attempts to close the modal.
   * If the modal is not loading and the user is not on the initial screen,
   * the user is routed to the landing page.
   * Otherwise, exit confirmation modal is displayed to confirm the close action.
   */
  onCloseModal() {
    if (
      !this.showEnrolledFactor ||
      this.errorCount >= 3 ||
      this.verifiedStatus
    ) {
      this.routeToSignIn();
    } else {
      this.showExitConfirmationModal = true;
    }
  }

  /**
   * Handles the action when the user decides to go back from the exit confirmation modal.
   * This function simply hides the confirmation modal.
   */
  onBack() {
    this.showExitConfirmationModal = false;
  }

  /**
   * Handles the action from child cmponent to go back to factor list page.
   */
  showFactorList() {
    this.selectedFactor = '';
    this.selectedFactorID = '';
    this.mfaStore.dispatch(resetVerifiedErrorCount());
  }
}

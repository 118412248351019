import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'patient-ui-verification-confirmation',
  templateUrl: './verification-confirmation.component.html',
  styleUrls: ['./verification-confirmation.component.scss'],
})
export class VerificationConfirmationComponent {
  @Output() submitForm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  constructor() {}
}
